<template>
  <div class="animated fadeIn">
    <b-row class="justify-content-md-center">
      <b-col sm="8">
        <b-card header-tag="header" footer-tag="footer">
          <loading
            :active.sync="isLoading"
            :is-full-page="false"
            :color="this.$config.LOADER.color"
            :opacity="this.$config.LOADER.opacity"
            :background-color="this.$config.LOADER.backgroundColor"
            :z-index="10000"
          ></loading>
          <h5 slot="header" class="mb-0">
            <b-button
              size="sm"
              :to="{
                name: 'admin.customer.detail',
                params: { id: this.$route.params.id }
              }"
              class="mr-2"
              ><i class="fa fa-chevron-left"></i> Буцах</b-button
            >
            Илүү төлөлтөөр зээл хаах
          </h5>
          <b-table bordered small :fields="columns" :items="items">
            <template v-slot:head(checkbox)>
              <b-form-checkbox v-model="selectAll"></b-form-checkbox>
            </template>
            <template v-slot:cell(checkbox)="data">
              <b-form-checkbox
                type="checkbox"
                :value="data.item"
                v-model="selected"
              ></b-form-checkbox>
            </template>
            <template v-slot:cell(index)="data">
              <span v-if="data.index <= items.length">{{
                data.index + 1
              }}</span>
            </template>
            <template v-slot:cell(days)="data">
              <strong v-if="data.index <= items.length">
                {{ data.item.days }} өдөр</strong
              >
            </template>
          </b-table>

          <dl class="row" v-if="items != null && items.length > 0">
            <dt class="offset-sm-8 col-sm-2  text-right">Илүү төлөлт</dt>
            <dd class="col-sm-2  text-right">
              <h5 class="text-primary mb-0">{{ payment.over | currecry }}₮</h5>
            </dd>

            <dt class="offset-sm-8 col-sm-2  text-right">Хаах дүн</dt>
            <dd class="col-sm-2  text-right">
              <h5 class="text-primary mb-0">{{ totalAmount | currecry }}₮</h5>
            </dd>
          </dl>

          <b-button
            variant="success pull-right"
            :disabled="
              !isOverduePayment &&
                (payment.over < totalAmount * 0.97 || totalAmount == 0)
            "
            @click="save"
          >
            Зээл хаах
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
export default {
  name: "Customer.OverdueLoanClosing",
  components: { datePicker },
  props: {
    loans: {
      type: Object || Array,
      default: () => {}
    },
    overdue: {
      type: String,
      default: "0"
    }
  },
  data: () => {
    return {
      isLoading: false,
      isOverduePayment: false,
      payment: {
        over: 0,
        under: 0,
        undueLoss: 0
      },
      today: moment().format("YYYY-MM-DD"),
      totalAmount: 0,
      items: [],
      columns: [
        { label: "", key: "checkbox" },
        { label: "№", key: "index", class: "text-center" },
        { label: "Дансны дугаар", key: "accountNumber" },
        {
          label: "Авсан огноо",
          key: "loanDate",
          sortable: true,
          sort_key: "loan_date"
        },
        { label: "Ашигласан хоног", key: "days" },
        {
          label: "Хаах огноо",
          key: "expiryDate",
          sortable: true,
          sort_key: "expiry_date"
        },
        { label: "Хаах дүн", key: "closingAmount", class: "text-right" },
        {
          label: "Авсан хэмжээ",
          key: "loanAmount",
          class: "text-right",
          sortable: true,
          sort_key: "loan_amount"
        },
        {
          label: "Үлдэгдэл",
          key: "loanBalance",
          class: "text-right",
          sortable: true,
          sort_key: "loan_balance"
        }
      ],
      selected: [],
      selectAll: false,
      transactionDate: moment().format("YYYY-MM-DD")
    };
  },
  watch: {
    selectAll: {
      handler(selectAll) {
        this.$data.selected = [];
        if (this.selectAll) {
          for (let i in this.items) {
            this.$data.selected.push(this.items[i]);
          }
        }
      },
      deep: true
    },
    selected: {
      handler(selected) {
        this.$data.totalAmount = 0;
        for (let i in selected) {
          this.$data.totalAmount += parseFloat(
            selected[i].closingAmount.replace(/,/g, "")
          );
        }
      },
      deep: true
    },
    transactionDate: {
      handler(transactionDate) {
        this.loadData();
      },
      deep: true
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData: function() {
      this.$data.selected = [];
      this.checkSession();
      this.isLoading = true;
      this.$http
        .get(
          this.$config.API_URL + "CustomerWebService/get_loan_active_account",
          {
            params: {
              id: this.$route.params.id,
              date: this.$data.transactionDate
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true
          }
        )
        .then(
          (response) => {
            // //console.log(response)
            this.isLoading = false;
            this.$data.items = response.body.data;

            for (var i in this.$data.items) {
              if (!this.$data.items[i].isClosed) {
                this.$data.isActiveLoan = true;
                if (
                  this.$data.today > this.$data.items[i].expiryDate &&
                  this.$data.items[i]
                ) {
                  this.$data.isOverduePayment = true;
                }
              }
            }

            this.calcOverAndUnderPayment();
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    calcOverAndUnderPayment: function() {
      if (this.$data.items != null && this.$data.items.length > 0) {
        if (
          this.$data.items[0].feeAmount != "0" &&
          this.$data.items[0].feeAmount != ".00"
        ) {
          this.$data.payment.undueLoss = parseFloat(
            this.$data.items[0].feeAmount.replace(/,/g, "")
          );
        }

        if (
          this.$data.items[0].overPaymentAmount != "0" &&
          this.$data.items[0].overPaymentAmount != ".00"
        ) {
          this.$data.payment.over = parseFloat(
            this.$data.items[0].overPaymentAmount.replace(/,/g, "")
          );
        }

        if (
          this.$data.items[0].underPaymentAmount != "0" &&
          this.$data.items[0].underPaymentAmount != ".00"
        ) {
          this.$data.payment.under = parseFloat(
            this.$data.items[0].underPaymentAmount.replace(/,/g, "")
          );
        }
      }
    },
    save: function() {
      let _ids = [];
      for (let i in this.$data.selected) {
        _ids.push(this.$data.selected[i].id);
      }

      if (_ids.length == 0) {
        this.showToast("Анхаар", "Зээл сонгоогүй байна", "warning");
      } else {
        this.isLoading = true;

        this.$http
          .post(
            this.$config.API_URL + "CustomerWebService/overpayment_to_close",
            {
              data: JSON.stringify({
                token: this.$store.getters.token,
                email: this.$store.getters.email,
                customerId: this.$route.params.id,
                loanIds: _ids,
                date: moment(this.$data.transactionDate).format("YYYY-MM-DD")
              })
            },
            { headers: this.$store.getters.httpHeader, emulateJSON: true }
          )
          .then(
            (response) => {
              this.isLoading = false;
              if (response.body.responseResultType == "SUCCESS") {
                this.showToast(
                  "Амжилттай",
                  "Зээл амжилттай хаагдлаа",
                  "success"
                );
                this.loadData();
              } else {
                this.showToast(
                  "Алдаа",
                  "Алдаа гарсан байна. Дахин оролдоно уу",
                  "danger"
                );
              }
            },
            (response) => {
              this.isLoading = false;
              this.showToast(
                "Алдаа",
                "Алдаа гарсан байна. Дахин оролдоно уу",
                "danger"
              );
            }
          );
      }
    }
  }
};
</script>
